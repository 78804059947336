import {GeoHit} from "../../types/StoreHit";

const StoreDetails = ({
                          currentStore,
                          onClick
                        }: {
  currentStore: GeoHit|null ;
  onClick: () => void;
}): JSX.Element => {

  return (
    <article  className={'overflow-hidden shadow-lg rounded-lg m-0 m-1 p-4 py-0 flex'} >
        <button type="button" 
        className="absolute cursor-pointer right-1 bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
        onClick={() => onClick()}>
              <span className="sr-only">Close menu</span>
              
              <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>

        <div className="sm:p-6">
        <h2 className={'font-medium text-lg'}>{currentStore?.name}</h2>
        <p className={'font-normal text-sm'}>
          {currentStore?.city} {currentStore?.country}
        </p>
        <div className={'flex gap-2 my-2 flex-wrap'}>
          {currentStore?.services.map((service: string) => (
            <span
              key={`${service}-${currentStore?.objectID}`}
              className={
                'bg-purple-100 text-sm font-normal text-purple-800 px-2 gap-13 rounded-full'
              }
            >
              {service}
            </span>
          ))}
        </div>
        <p className={'font-normal text-sm'}>
            ObjectID {currentStore?.objectID}
        </p>
      </div>
    </article>
  );
};

export default StoreDetails;