import React, { useState, Component } from 'react';
import ReactMapboxGl, { ZoomControl, Marker } from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import {Map} from 'mapbox-gl';
import {connectGeoSearch} from 'react-instantsearch-dom';
import {GeoSearchProvided} from 'react-instantsearch-core'
import {LngLat} from "../../types/LngLat";

import {ReactComponent as MarkerUnselected} from "../../assets/marker.svg";
import {ReactComponent as MarkerSelected} from "../../assets/marker-selected.svg";
//import SearchAsMoving from "./SearchAsMoving";
import {comparePosition} from "../../lib/comparePosition";
import { GeoHit } from '../../types/StoreHit';


interface MapState {
  lat: number|undefined,
  lng: number|undefined,
  zoom: number,
  markers: LngLat[]
  isUserInteraction: boolean,
  userInteractionEnabled: boolean
  currentStore: LngLat | null
}

interface MapProps {
  currentLngLat: LngLat | null,
  currentStore: LngLat | null,
  onClickMarker: (marker: LngLat, hits: GeoHit[]) => void
  //onClickMarker: () => void
}

const ReactMap = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX_TOKEN as string,
});

class MapComponent extends Component<GeoSearchProvided & MapProps, MapState> {
  map: any;
  
  state = {
    lat: 43,
    lng: -79,
    zoom: 9,
    markers: [],
    currentStore: this.props.currentLngLat || [-79 , 43],
    isUserInteraction: true,
    userInteractionEnabled: true
  };
  
  constructor(props: GeoSearchProvided & MapProps) {
    super(props);
    this.centerMapOnCoordinates = this.centerMapOnCoordinates.bind(this);
    this.onMapInteraction = this.onMapInteraction.bind(this);
  }

  componentWillReceiveProps(nextProps: Readonly<GeoSearchProvided & MapProps>) {
    const {hits, currentLngLat, currentStore} = nextProps
    const markers: LngLat[] = hits.map(
      ({ _geoloc }: { _geoloc: { lat: number; lng: number } }) => {
        return [_geoloc.lng, _geoloc.lat];
      }
    );

  
    //initially, generate hits by IP address, after, by map interaction or by autocomplete input
    if (markers.length != 0 || currentLngLat?.[0] != 99){

      if (this.state.isUserInteraction ){
        this.setState({ isUserInteraction: false }, () => {
          this.centerMapOnCoordinates(currentLngLat?.[0] == 99 ? markers[0]: currentLngLat)});
       }
      else{

        if (this.state.lng != currentLngLat?.[0]){
          this.setState({ lng: currentLngLat?.[0], lat: currentLngLat?.[1] }, () => {
            this.centerMapOnCoordinates(currentLngLat?.[0] == 99 ? markers[0]: currentLngLat)});
          }

  
      }

    }

   this.setState({markers}, () => {
    console.log("setState({markers}")
        })

   this.setState({currentStore}, () => {
        console.log("setState({currentStore}")
        })
  }

centerMapOnCoordinates(coordinates: LngLat|null) {
  console.log("flyTo:"+coordinates)
  this.map.flyTo({
    essential: false,
    center: coordinates,
    zoom: 10,
  })

  
}

  onMapInteraction(map: Map) {
    if (this.state.userInteractionEnabled) {
      const { refine } = this.props;

      refine({
        northEast: map.getBounds().getNorthEast(),
        southWest: map.getBounds().getSouthWest(),
      });
    }
  }

  render() {
    return (
      <div className={'h-full w-full relative'}>
        <div className={'h-full w-full'}>
          <ReactMap
            ref={(e) => {
              this.map = e?.state.map;
            }}
            style="mapbox://styles/mapbox/streets-v11"
            containerStyle={{
              height: '100%',
              width: '100%',
              position: 'relative',
              display: 'block'
              //overflow: 'hidden'
              }
            }
            onMoveEnd={this.onMapInteraction}
          >
            <>
              {
                this.state.markers.map((marker: LngLat) => {
                  return <Marker coordinates={marker} onClick={()=>this.props.onClickMarker(marker, this.props.hits)}>
                    {comparePosition(marker, this.state.currentStore) ? <MarkerSelected/> : <MarkerUnselected className="cursor-pointer"/>}
                  </Marker>
                })
              }
            <ZoomControl position={'top-right'} />
            </>
          </ReactMap>
          {/* 
          <SearchAsMoving checked={this.state.userInteractionEnabled} onChange={(userInteractionEnabled) => this.setState({userInteractionEnabled})}/>
*/}
        </div>

      </div>
    );
  }
}

export default connectGeoSearch(MapComponent)