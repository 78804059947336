import React, {useState, useCallback, useMemo} from 'react';
import {ReactComponent as Logo} from '../../assets/logo.svg'
import './App.css';
import {InstantSearch, Hits, Configure, RefinementList, SearchBox} from 'react-instantsearch-dom'
import {indexName, searchClient} from "../../lib/algoliaClient";
import {GeoHit} from "../../types/StoreHit";
import StoreComponent from "../StoreComponent/StoreComponent";
import StoreDetails from "../StoreComponent/StoreDetails";
//import Header from "../Header/Header";
import Map from "../Map/Map"
import Autocomplete from "../Autocomplete/Autocomplete"
import {MapboxAddressComponent, SuggestionComponent} from "../Autocomplete/AutocompleteComponents"
import {LngLat} from "../../types/LngLat";
import { createSuggestionsPlugin } from "../../AutocompletePlugins/query_suggestion_plugin"
import { createMapboxGeocodingPlugin } from "../../AutocompletePlugins/mapbox_geocoding_plugin"

function App () {

const [searchState, setSearchState] = useState<Record<string, any>>({});
const [currentStore, setCurrentStore] = useState<GeoHit | null>(null);
//initial coordinates set to (99,99) and let aroundLatLngViaIP=true
const [currentLat, setCurrentLat] = useState(99);
const [currentLng, setCurrentLng] = useState(99);
const [isUserInteraction, setIsUserInteraction] = useState(true);

// Handle search results updates

// const onSubmit = useCallback(({ state }) => {
//   setSearchState((searchState) => ({
//     ...searchState,
//     query: state.query,
//   }));
//   }, []);

// // Click on the little cross on autocomplete field
// const onReset = useCallback(() => {
//   setSearchState((searchState) => ({
//     ...searchState,
//     query: '',
//   }));
// }, []);

const setCurrentStoreByMarker = (marker: LngLat, hits: GeoHit[]) => {
  const currentStore = hits.find(store => store._geoloc.lng == marker[0] && store._geoloc.lat == marker[1] );
   setCurrentStore(currentStore? currentStore : null);
  
}

// Memoize plugins to then leverage useCallback hooks
const plugins = useMemo(() => {
  const mapboxGeocodingPlugin = createMapboxGeocodingPlugin({
    fuzzyMatch: false,
    autocomplete: true,
    types: ['country', 'place', 'poi'],
    country: ['CA', 'US'],
    access_token: process.env.REACT_APP_MAPBOX_TOKEN!,
  },
  MapboxAddressComponent,
  (item) => {
    setCurrentLng(item.geometry.coordinates[0]);    
    setCurrentLat(item.geometry.coordinates[1]);
    setCurrentStore(null);
  }
);

  const querySuggestionPlugin = createSuggestionsPlugin(
    searchClient,
    indexName as string,
    (query) => {
      setSearchState((searchState) => ({
        ...searchState,
        query: query,
      }));
    },
    (item) => {
      setCurrentLng(item._geoloc.lng);    
      setCurrentLat(item._geoloc.lat);
      setCurrentStore(null);
     }, 
    SuggestionComponent
   );

  return [mapboxGeocodingPlugin, querySuggestionPlugin];
}, []);


  return (
    <div className="flex w-full h-screen flex-col ">

{/* removed header, place logo at the top left
<Header>

    onStateChange={onSubmit}
      onSubmit={onSubmit}
    onReset={onReset} 
  </Header>
*/}
      <InstantSearch searchClient={searchClient} indexName={indexName}>
        <Configure
          aroundLatLngViaIP={currentLat == 99}
          aroundLatLng={currentLat == 99? "" : currentLat+','+currentLng}
         />
        <div className={'flex  h-full w-full backdrop-blur-sm'}>
          <div className={'flex flex-col w-1/4 '}>
<div className={'m-2 sticky top-0'}>
          <div className={'m-2 backdrop-blur-sm backdrop-filter: blur(10px);'}>
            <Logo className={'w-auto h-16'} />
          </div>
            <div className={'m-2'}>
                {<Autocomplete
                    initialState={{
                      query: searchState.query,
                    }}
                    placeholder={'Enter city or store name'}
                    openOnFocus={true}
                    plugins={plugins}
                />}
            </div>
            <div className={'m-2'}>
              <RefinementList attribute={'services'} operator={'and'}/>
              <RefinementList attribute={'country'}/>
            </div>
          </div>

          <div className={'m-2 overflow-scroll'}>
            <Hits<GeoHit> hitComponent=
              {hit  => 
                <StoreComponent 
                  store={hit.hit} 
                  onClick={setCurrentStore} 
                  currentStore={currentStore} 
                  key={hit.hit.objectID}
                 />
              }
            />
          </div>

    </div>
          <div className={'flex flex-col w-full'}>
            <div className={`${currentStore? '':'hidden'} absolute z-10 m-5 bg-purple-100 hover:bg-purple-50 ring ring-purple-800` }>
                <StoreDetails currentStore={currentStore} onClick={()=>setCurrentStore(null)}/>
            </div>
  
            <div className={'flex h-screen w-full z-0'}>
              <Map 
                currentLngLat={[currentLng, currentLat] } 
                currentStore={currentStore ? [currentStore._geoloc.lng, currentStore._geoloc.lat] : null} 
                onClickMarker={setCurrentStoreByMarker}/>
            </div>

          </div>
   
          </div>
      </InstantSearch>

      </div>
  );
}

export default App;
